import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import routes from '../router/modules/index.js';

// import {listRouter} from './modules/list'

//  const routesd = [
//   ...listRouter,
// ]
const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: () => ({ y: 0 }),
  // routesd,
  routes
})

// 页面进度条
NProgress.configure({
	easing: 'ease',  // 动画方式
	speed: 500,  // 递增进度条的速度
	showSpinner: false, // 是否显示加载ico
	trickleSpeed: 200, // 自动递增间隔
	minimum: 0.3 // 初始化时的最小百分比
})
router.beforeEach((to, from, next) => {
  // 启动加载条
  NProgress.start()
  // 更改title名称
  to.meta.title && (document.title = to.meta.title)
  next()
})
router.afterEach(() => {
  // 结束加载条
  NProgress.done()
})

export default router
