export default [
  {
    code: 'guide',
    name: '提示',
    children: [
      {
        path: '/docs/message',
        name: 'message',
        meta: {
          type: 'component',
          title: 'Message 消息提示',
        },
        component: () => import('../../docs/message.md'),
      }
    ],
  },
];
