import md from './md.js';
import button from './button';
import form from './form.js';
import msg from './msg.js';
// import list from './list.js';

/**
 * nav的路由配置
 * [
 *    {
 *      code: '', // 分类的code
 *      name: '', // 分类名称
 *      children: [ // 该分类下所有组件
 *        {
 *          path: '', // 路由
 *          name: '', // 路由Name
 *          meta: {
 *            type: '', // 路由类型，component为组件
 *            title: '' // 显示在nav的名称
 *          }
 *        }
 *      ]
 *    }
 * ]
 */

export const NavConfig = [
  ...md,
  ...button,
  ...form,
  ...msg
];

const routes = NavConfig.map((item) => item.children).flat(Infinity);

routes.unshift({
  path: '/',
  code: '2222',
  name: 'Home',
  redirect: '/home',
  meta: {
    type: '/page',
    title: 'markm-ui|一款基于vue的ui组件库',
  },
});

export default routes;
