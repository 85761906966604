<template>
  <div class="main-container">
    <nav class="docs__nav-wrap docs-scroller--hide" v-if="tabsIndex != 'Home'">
      <div class="docs__logo">
        <!-- <div>Markm-Ui</div> -->
      </div>
      <nav-slide @itemss="itemss" />
    </nav>
    <section class="docs__content">
      <div class="docs__content-head">
        <div class="docs__logos" @click="itemss('Home')">
          <div>Markm-Ui</div>
        </div>
        <div class="links__item" :class="tabsIndex == 'Home' ? 'links__item_bg' : '' " @click="itemss('Home')" >首页</div>
        <div class="links__item" :class="tabsIndex == 'install' ? 'links__item_bg' : '' " @click="itemss('install')" >组件</div>
        <div class="links__item" :class="tabsIndex == 'FAQ' ? 'links__item_bg' : '' " @click="itemss('FAQ')" >更新日志</div>
        <a href="https://juejin.cn/user/1521379824639358/posts" target="_blank" class="links__item">博客</a>
        <a href="https://github.com/mmh007/markm-ui" target="_blank" class="icon__git links__item"></a>
      </div>
      <div class="content__wraper" v-if="tabsIndex != 'Home'">
        <div class="market-doc-container" :class="tabsRfghtX ? 'doc-width-X' : 'doc-width-Y'">
          <md-demo v-if="tabsIndex != 'FAQ'" :tabsIndex="tabsIndex"></md-demo>
          <!-- :previewOnly="true" @onUploadImg="onUploadImg" @onSave="codeSave" -->
          <MdEditor
            class="md-card"
            v-model="text"
            :previewOnly="true"
          >
          </MdEditor>
        </div>
        <router-view/>

        <div class="aside__wraper" :class="tabsRfghtX ? 'rightcard-X' : 'left-X'">
          <div class="aside__item banner">
            <h3 class="aside__item-head t">微信公众号</h3>
            <img src="../assets/img/gzh.jpg" alt="">
            <h3 class="aside__item-head t">微信交流群</h3>
            <img src="../assets/img/weixin.png" alt="">
            <h3 class="aside__item-head t">QQ交流群</h3>
            <img src="../assets/img/qq.jpg" alt="">
          </div>

          <div class="aside__item articles">
            <h3>文章推荐</h3>
            <ul>
              <li v-for="(item, index) in articles" :key="index">
                <a target="_blank" :href="item.src">{{item.title}}</a>
              </li>
            </ul>
            <div class="more">
              <a target="_blank" href="https://juejin.cn/user/1521379824639358/posts">查看更多</a>
            </div>
          </div>
          <!-- <a class="aside__item recommend">
            <img src="../assets/images/rbac-logo.png" alt="">
            <p>基于Vue+node技术栈搭建pc、移动端框架</p>
          </a> -->
          <a class="aside__item recommend">
            <h3 class="aside__item-head">Vue3 + node</h3>
            <p>基于Vue3+node技术栈搭建pc、移动端框架。</p>
          </a>
        </div>
      </div>
    </section>
    <div class="right-card"
        v-if="tabsIndex != 'Home'"
        @click="rightX"><i :class="tabsRfghtX ? 'm-icon-shanchu3' : 'm-icon-liebiao'"></i></div>
  </div>
    <m-hmoe :homeType="tabsIndex" @typeTable="typeTable" />
</template>

<script>
import NavSlide from './nav-slide.vue';
import MdDemo from './demo.vue';
import MHmoe from './home.vue';
import MdEditor from "md-editor-v3";
import "md-editor-v3/lib/style.css";
import { defineComponent,reactive,toRefs,getCurrentInstance,onMounted } from 'vue';
import { useRouter } from 'vue-router'
const articleList = require("../docs/install.md")
// const articleList = require("../docs/checkbox.md")
export default defineComponent ({
  name: 'DemoContent',
  components: {
    NavSlide,
    MdEditor,
    MdDemo,
    MHmoe
  },
  setup () {
    const router = useRouter()
    let proxy = getCurrentInstance();
    const data = reactive({
      tabsIndex: 'Home', // install
      tabsRfghtX: false,
      text: articleList,
      articles: [
        {
          src: 'https://mp.weixin.qq.com/s?__biz=Mzg2NTY5NTcyNQ==&mid=2247483749&idx=1&sn=c0bd3fd286c4b2c42ad1bdb2e0d18e84&chksm=ce5763e7f920eaf129913797d3d198a673cf5b723e424aed7e4b9d3ab5713ab5fcf0dee7f337#rd',
          title: 'Vue3版本更新,手把手教你项目搭建',
        },
        {
          src: 'https://blog.csdn.net/weixin_44873831/article/details/122240505?spm=1001.2014.3001.5502',
          title: '小程序云函数加载数据20条限制 分页更加丝滑',
        },
        {
          src: 'https://blog.csdn.net/weixin_44873831/article/details/108831336?spm=1001.2014.3001.5502',
          title: 'uni-app - 小程序分享功能',
        },
        {
          src: 'https://blog.csdn.net/weixin_44873831/article/details/107892157?spm=1001.2014.3001.5502',
          title: '微信小程序构建npm',
        },
        {
          src: 'https://blog.csdn.net/weixin_44873831/article/details/107032166?spm=1001.2014.3001.5502',
          title: '项目实战之微信、微信公众号登陆和支付调起',
        },
        {
          src: 'https://blog.csdn.net/weixin_44873831/article/details/120720530?spm=1001.2014.3001.5502',
          title: '小程序 腾讯兔小巢 对接',
        },
        {
          src: 'https://blog.csdn.net/weixin_44873831/article/details/109115817?spm=1001.2014.3001.5502',
          title: 'js骚操作之模糊搜索，我喜欢',
        },
        {
          src: 'https://blog.csdn.net/weixin_44873831/article/details/122475636?spm=1001.2014.3001.5502',
          title: 'H5跳转小程序 (成功案例)',
        },
      ],
    })
    // 切换.md
    const itemss = (e) => {
      data.tabsIndex = e;
      goTop()
      switch (e) {
        case 'install':
          data.text = require("../docs/install.md")
        break;
        case 'quick_start':
          data.text = require("../docs/quick-start.md")
        break;
        case 'button':
          data.text = require("../docs/button.md")
        break;
        case 'checkbox':
          data.text = require("../docs/checkbox.md")
        break;
        case 'radio':
          data.text = require("../docs/radio.md")
        break;
        case 'switch':
          data.text = require("../docs/switch.md")
        break;
        case 'input':
          data.text = require("../docs/input.md")
        break;
        case 'form':
          data.text = require("../docs/form.md")
        break;
        case 'dialog':
          data.text = require("../docs/dialog.md")
        break;
        case 'message':
          data.text = require("../docs/message.md")
        break;
        case 'FAQ':
          data.text = require("../../FAQ.md")
        break;
        case 'Home':
          router.push({
            path: '/home'
          })
        break;
      }
    }
    const typeTable = (e) => {
      data.tabsIndex = e
      itemss(e)
    }
    // 回到顶部
    const handleScroll = () => {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
        proxy.scrollTop = scrollTop;
      }
		const	goTop = () => {
      // let timer = setInterval(() => {
        // let ispeed = Math.floor(-proxy.scrollTop / 10);
        // document.documentElement.scrollTop = document.body.scrollTop =
        //   proxy.scrollTop + ispeed;
        document.documentElement.scrollTop = 0;
        if (proxy.scrollTop === 0) {
          // clearInterval(timer);
        }
      // }, 8);
    }
    // 右侧导航
    const rightX = () => {
      data.tabsRfghtX = !data.tabsRfghtX
    }
    // 判断手机端pc端
    const wbloePc = () => {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (flag) {
        data.tabsRfghtX = false
      } else {
        data.tabsRfghtX = true
      }
    }
    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    })
    wbloePc()
    return {
      ...toRefs(data),
      itemss,
      typeTable,
      handleScroll,
      goTop,
      rightX
    }
  }
})

</script>

<style lang="scss">
.md-card {
  height: 100% !important;
  background: none !important;
}
.main-container {
  padding-left: 240px;
  // background: #fafeff;
  background: #fafbfb;
  overflow-y: hidden;
  padding-bottom: 60px;
  .docs__nav-wrap {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 240px;
    box-shadow: 0 1px 10px #bbc3cc;
    overflow: auto;
    cursor: pointer;
    .docs__logo {
      width: 100%;
      height: 60px;
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      line-height: 60px;
      text-align: center;
      background: #2d8cf0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .docs__content {
    min-height: 100%;
  }
  .docs__content-head {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: 60px;
    // padding: 30px;
    background: #fff;
    box-shadow: 0 0 8px #ccc;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .docs__logos {
      display: flex;
      flex-direction: column;
      text-align: center;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1001;
      width: 240px;
      overflow: auto;
      cursor: pointer;
      height: 60px;
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      line-height: 60px;
      background: #2d8cf0;
    }
    .links__item {
      cursor: pointer;
      height: 32px;
      margin-right: 20px;
      line-height: 32px;
      font-size: 16px;
      color: #515151;
      transition: all .3s;
      &:hover {
        color: #2d8cf0;
        // color: #0e80eb;
        border-color: #0e80eb;
        border-bottom: 2px solid #2d8cf0;
        display: inline-block;
        float: none;
        transition: all .2s ease;
      }
    }
    .links__item_bg {
      color: #2d8cf0;
      border-color: #0e80eb;
      border-bottom: 2px solid #2d8cf0;
      display: inline-block;
      float: none;
      transition: all .2s ease;
    }
    .icon__git {
      display: inline-block;
      width: 40px;
      height: 40px;
      background-image: url('../assets/img/github.png');
      background-size: 24px auto;
      background-repeat: no-repeat;
      background-position: center;
      &:hover {
        background-image: url('../assets/img/github-active.png');
      }
    }
  }

  .content__wraper {
    display: flex;
    padding: 90px 30px 0 30px;
  }
  .aside__wraper {
    width: 240px;
    margin-left: 18px;
    .aside__item {
      margin-bottom: 20px;
      background: #fff;
      box-shadow: 0 0 10px #ccc;
      border-radius: 4px;
      &.banner {
        padding: 10px;
        text-align: center;
        img {
          width: 90%;
        }
        .t {
          padding: 10px 0;
        }
      }
      &.articles {
        h3 {
          line-height: 40px;
          text-align: center;
          border-bottom: 1px solid rgba(151, 151, 151, 0.1);
        }
        ul {
          li {
            a {
              display: block;
              padding: 10px;
              cursor: pointer;
              &:hover {
                background: #f1f1f1;
              }
            }
          }
        }
        .more {
          border-top: 1px solid rgba(151, 151, 151, 0.1);
          line-height: 40px;
          text-align: center;
          a {
            color: #2d8cf0;
          }
        }
      }

      &.recommend {
        display: block;
        cursor: pointer;
        transition: all .3s;
        overflow: hidden;
        &:hover {
          background: #f0fafe;
          transform: translateY(-6px);
        }
        h3 {
          padding: 10px 10px 0;
        }
        img {
          width: 100%;
        }
        p {
          padding: 10px;
          color: #666;
        }
      }
    }
  }

  .market-doc-container {
    flex: 1;
    > h1, > h2, > h3, > h4, > h5, > h6 {
      font-weight: 400;
      margin: 12px 0;
    }
    > h2 {
      margin: 0;
      font-size: 24px;
      font-weight: 700;
    }
    > h3 {
      margin: 40px 0 0;
      font-size: 22px;
    }

    > table {
      width: 100%;
      margin-top: 20px;
      border: 1px solid #EBEEF5;
      border-collapse: collapse;
      border-spacing: 0;
      empty-cells: show;
      background: #fff;
      th, td {
        padding: 10px;
        border: 1px solid #EBEEF5;
        font-size: 14px;
        font-weight: normal;
        font-family: Consolas,Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
      }
      th {
        font-weight: bold;
        background: #fafafa;
      }
    }
    > p {
      margin-top: 10px;
    }
    > pre {
      margin-top: 20px;
      padding: 20px 10px;
      border: 1px solid #ebeef5;
      border-radius: 4px;
      background: #fafafa;
      font-family: Consolas,Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
      code {
        font-size: 14px;
        font-weight: normal;
        font-family: Consolas,Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
        background: transparent;
      }
    }
  }
}
.default-theme pre:before {
    content: "" !important;
    display: none !important;
}
.default-theme pre {
  margin: 20px 5px;
  // box-shadow: none !important;
  box-shadow: #f0fafe 0px 2px 8px -2px !important;
  code {
    position: relative;
    padding: 20px !important;
    margin-top: 20px !important;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 6px #dcdee2;
  }
}
.right-card {
  display: none !important;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 5%;
  z-index: 1100;
  right: 6%;
  background: #ffffff;
  box-shadow: 0 0 6px #909399;
  cursor: pointer;
  i {
    font-size: 30px;
    color: #666;
  }
}
.left-X {
  transition: all .3s;
  transform: translateX(110px);
  display: none;
}
.rightcard-X {
  // transition: all .3s !important;
  transform: translateX(0px) !important;
}
.doc-width-X {
  transition: all .3s !important;
  width: 60% !important;
}
.doc-width-Y {
  transition: all .3s  !important;
  width: 98% !important;
  overflow: hidden !important;
}
</style>
