export default [
  {
    code: 'guide',
    name: '基础',
    children: [
      {
        path: '/docs/button',
        name: 'button',
        meta: {
          type: 'component',
          title: 'Button 按钮',
        },
        component: () => import('../../docs/button.md'),
      },  {
        path: '/docs/checkbox',
        name: 'checkbox',
        meta: {
          type: 'component',
          title: 'Checkbox 复选框',
        },
        component: () => import('../../docs/checkbox.md'),
      }, {
        path: '/docs/radio',
        name: 'radio',
        meta: {
          type: 'component',
          title: 'Radio 单选框',
        },
        component: () => import('../../docs/radio.md'),
      }, {
        path: '/docs/switch',
        name: 'switch',
        meta: {
          type: 'component',
          title: 'Switch 开关',
        },
        component: () => import('../../docs/switch.md'),
      }
    ],
  },
];
