import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// md
import MdEditor from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';

// 组件内测
import '../packages/font/iconfont.css'
import MarkmUi from '../packages'

// 公共样式
import '../examples/assets/index.css'
const app = createApp(App)
app.use(router)
app.use(MarkmUi)
app.use(MdEditor)
app.mount('#app')
