<template>
  <div id="app">
    <template v-if="isComponent">
      <main-content />
    </template>
    <router-view v-else />
  </div>
</template>

<script>
import { defineComponent,reactive,computed,getCurrentInstance,toRefs,ref,watchEffect } from 'vue';
import { useRouter } from "vue-router";
import MainContent from './layout/content.vue';
export default defineComponent({
  name: 'App',
  components: {
    MainContent,
  },
  setup () {
    // 定义路由跳转对象
		const router = useRouter();
    const state = reactive({
      isComponent: false,
    })
    const instance = getCurrentInstance()
    const handleClick = ()=> {
      instance.proxy.$message({
      text: '登录成功',
        // timeout:'4000',
        // textColor:"#000",
        // bgColor:"red",
        // color: "red",
        // icon: 'm-icon-shanchu',
        type: 'success'
      })
    }
    watchEffect(() => {
      router.getRoutes().map((item, index) => {
        if(item.meta.type === 'component'){
          state.isComponent = true
        }
      })
    })
    return {
      ...toRefs(state),
      handleClick,
    }
  }
})
</script>

<style lang="scss">

</style>
