<template>
  <div v-if="homeType == 'Home'" class="home">
    <div class="top-card">
      <h3>Ma</h3>
      <div class="li-tit">
        <p>一套适合开发者使用的轻量级UI组件库，完美支持vue3</p>
        <p>完美支持vue3版本，更小的体积，更快的响应，更强的拓展性，让我们一起向未来，拥抱这精彩的vue世界！</p>
      </div>
      <div class="li-btn">
        <m-button type="primary" width="5%" @click="onClick('install')">立即开始</m-button>
        <m-button width="5%" @click="onClick('href')">Gitee</m-button>
      </div>
    </div>
    <ul class="ul-label">
      <li>
        <i class="m-icon-tijixiao"></i>
        <h6>体积较小</h6>
        <p>组件体积更小 可灵活使用</p>
      </li>
      <li>
        <i class="m-icon-kuaisu"></i>
        <h6>响应快速</h6>
        <p>composition API 响应更快</p>
      </li>
      <li>
        <i class="m-icon-wangluotuopu"></i>
        <h6>拓展性强</h6>
        <p>自定义组件 满足项目所有场景</p>
      </li>
      <li>
        <i class="m-icon-xingneng"></i>
        <h6>性能完美</h6>
        <p>基于vue3版本组件库</p>
      </li>
      <li>
        <i class="m-icon-shituxuanranmoshi"></i>
        <h6>渲染流畅</h6>
        <p>极少的代码量 极简配置项</p>
      </li>
    </ul>
    <ul class="link-card">
      <p>链接</p>
      <li><a href="https://github.com/mmh007/markm-ui" target="_blank">Github</a></li>
      <li><a href="https://www.npmjs.com/search?q=markm-ui" target="_blank">NPM</a></li>
      <li><a target="_blank" @click="onClick('FAQ')">更新日志</a></li>
    </ul>
    <ul class="contr-card link-card">
      <p>贡献人员</p>
      <li><a href="https://github.com/mmh007" target="_blank"><img src="../assets/img/logo.png" alt=""> mmh007</a></li>
      <li><a href="https://gitee.com/markMaMeiHua" target="_blank"><img src="../assets/img/head.jpg" alt=""> Mark.Ma</a></li>
    </ul>
  </div>
</template>

<script>
import { defineComponent,reactive,toRefs } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {
    homeType: {
      type: String
    }
  },
  setup(props,{emit}) {
    const router = useRouter()
    const data = reactive({
      sada: ''
    })
    const onClick = (e) => {
      if (e == 'href') {
        window.open('https://gitee.com/open_vue/markm-ui', '_blank')
      } else {
        emit('typeTable',e)
      }
    }
    return {
      ...toRefs(data),
      onClick
    }
  },
})
</script>

<style lang="scss" scoped>
  .home {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding-top: 100px;
    min-height: calc(100vh - 160px);
    background: rgba(9,195,190,.02);
    .top-card {
      text-align: center;
      h3 {
        width: 200px;
        height: 190px;
        background: #409eff;
        text-align: center;
        line-height: 190px;
        color: #fff;
        font-size: 100px;
        border-radius: 20px;
        box-shadow: 0 0 8px #2d8cf0;
        display: inline-block;
      }
      .li-tit {
        color: #4a5264;
        p:first-child {
          font-size: 32px;
          padding-top: 20px;
          font-weight: bold;
        }
        p:last-child {
          font-size: 18px;
          padding: 25px 0 0;
        }
      }
      .li-btn {
        padding-top: 60px;
        .m-button {
          padding: 15px 0;
        }
        .m-button:first-child {
          margin-right: 36px;
        }
      }
    }
    .ul-label {
      width: 70%;
      height: auto;
      overflow: hidden;
      margin: 0 auto;
      text-align: center;
      margin-top: 5vh;
      li {
        width: 19%;
        padding: 20px 8px 25px 8px;
        float: left;
        list-style: none;
        background: #fff;
        border-radius: 5px;
        box-sizing: border-box;
        transition: all .3s ease-in-out;
        // margin-right: 1%;
        margin: 10px 1% 10px 0;
        cursor: pointer;
        // transform: translateY(-6px);
        &:hover {
          background: #dbf5f5;
          transform: translateY(-5px);
          i {
            color: #409eff;
          }
        }
        h6 {
          font-size: 18px;
          color: #1f2f3d;
          font-weight: 400;
          padding: 20px 15px;
          margin: 0;
        }
        p {
          font-size: 14px;
          color: #919cac;
          padding: 0;
          line-height: 20px;
          text-align: center;
        }
        i {
          font-size: 40px;
          color: #1f2f3d;
          &:hover {
            color: #002fff;
          }
        }
      }
    }
    .link-card {
      width: 100%;
      height: auto;
      overflow: hidden;
      text-align: center;
      margin-top: 3vh;
      p {
        font-size: 20px;
        font-weight: 600;
        color: #515a6e;
        margin: 0;
        line-height: 50px;
      }
      li {
        display: inline-block;
        line-height: 30px;
        color: #0b6bc5;
        font-size: 16px;
        padding: 5px 10px;
        cursor: pointer;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #0b6bc5;
          cursor: pointer;
        }
        img {
          width: 1.2vw;
          border-radius: 50%;
          margin-right: 7px;
        }
      }
    }
    .contr-card {
      li {
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #0b6bc5;
          cursor: pointer;
        }
        img {
          width: 1.2vw;
          border-radius: 50%;
          margin-right: 7px;
        }
      }
    }
  }
</style>
