<template>
  <div class="demo"
      v-if="tabsIndex == 'button' ||
      tabsIndex == 'dialog' ||
      tabsIndex == 'input' ||
      tabsIndex == 'switch' ||
      tabsIndex == 'radio' ||
      tabsIndex == 'checkbox' ||
      tabsIndex == 'form' ||
      tabsIndex == 'message'">
    <div v-if="tabsIndex == 'button'" class="row button">
      <m-button >按钮</m-button>
      <m-button type="primary">按钮</m-button>
      <m-button type="success">按钮</m-button>
      <m-button plain type="primary">按钮</m-button>
      <m-button plain icon="m-icon-shanchu" round type="info">按钮</m-button>
      <m-button plain disabled icon="m-icon-shanchu" round type="primary">按钮</m-button>
      <m-button plain circle type="success">按</m-button>
      <m-button plain circle icon="m-icon-dianhua" type="primary"></m-button>
    </div>
    <div v-if="tabsIndex == 'dialog'" class="row dialog">
      <m-dialog width="30%" top="10%" title="温馨提示" v-model="visible">
        <span>输入的内容文字</span>
        <template v-slot:footer>
				  <m-button type="primary" @click="onConfirm">确认</m-button>
          <m-button @click="onVisible">取消</m-button>
        </template>
      </m-dialog>
      <!-- 触发按钮，显示隐藏弹层 -->
      <m-button type="primary" @click="onVisible">显示弹出框</m-button>
    </div>
    <div v-if="tabsIndex == 'input'" class="row input">
      <m-input
        width="20%"
        height="30px"
        name="name"
        placeholder='请输入内容'
        type="password"
        ></m-input>
      <m-input
        top="0"
        width="20%"
        clearable
        placeholder='请输入内容'
        v-model="inputValue0"
        ></m-input>
      <m-input
        width="20%"
        show-password
        clearable
        placeholder='请输入内容'
        v-model="inputValue1"
        ></m-input>
      <m-input
        width="20%"
        placeholder='请输入内容'
        show-password
        v-model="inputValue2"
      ></m-input>
      <m-input
        top="0"
        width="20%"
        disabled
        placeholder='禁止输入'
        ></m-input>
    </div>
    <div v-if="tabsIndex == 'switch'" class="row switch">
      <m-switch v-model="switchValue0"></m-switch>
      <!-- active-color -->
      <m-switch v-model="switchValue1" active-color="#13ce66" inactice-color="#7c8db3"></m-switch>
      <!-- 接收change -->
      <m-switch v-model="switchValue2" @change="onChange">
        <template v-slot:open>
          <span>关</span>
        </template>
        <template v-slot:close>
          <span>开</span>
        </template>
      </m-switch>
    </div>
    <div v-if="tabsIndex == 'radio'" class="row radio">
      <m-radio label="0" v-model="radioValue1">男</m-radio>
      <m-radio label="1" v-model="radioValue1">女</m-radio>
      <m-radio label="2" v-model="radioValue1">大</m-radio>
      <m-radio label="3" v-model="radioValue1">小</m-radio>
      <m-radio label="3" disabled v-model="radioValue1">禁用</m-radio>
      <m-radio label="1" disabled v-model="radioValue2">禁用</m-radio>
    </div>
    <div v-if="tabsIndex == 'checkbox'" class="row checkbox">
      <m-checkbox v-model="checkboxValue3" label="Vue3"></m-checkbox>
      <m-checkbox v-model="checkboxValue4" label="React"></m-checkbox>
      <m-checkbox v-model="checkboxValue5" label="Node.js"></m-checkbox>

      <m-checkbox v-model="checkboxValue">选中</m-checkbox>
      <m-checkbox v-model="checkboxValue0">选中</m-checkbox>

      <m-checkbox v-model="checkboxValue0" disabled>选中</m-checkbox>
      <m-checkbox v-model="checkboxValue2" disabled>选中</m-checkbox>
    </div>
    <div v-if="tabsIndex == 'form'" class="row form">
      <m-form :model='model' label-width="60px" label-align="left">
        <m-form-item label="用户名">
          <m-input
            width="30%"
            height="30px"
            size="12px"
            clearable
            v-model="model.username"
            placeholder='请输入用户名'
            ></m-input>
        </m-form-item>
        <m-form-item label="状态">
          <m-switch v-model="model.active"></m-switch>
        </m-form-item>
        <m-form-item label="尺寸">
          <m-radio label="0" v-model="radioValue1">大</m-radio>
          <m-radio label="1" v-model="radioValue1">小</m-radio>
        </m-form-item>
          <m-button type="primary" width="30%" @click="onMessage('success')">提交</m-button>
      </m-form>
    </div>
    <div v-if="tabsIndex == 'message'" class="row form">
      <m-button type="success" width="20%" @click="onMessage('success')">success</m-button>
      <m-button type="info" width="20%" @click="onMessage('info')">info</m-button>
      <m-button type="warning" width="20%" @click="onMessage('warn')">warning</m-button>
      <m-button type="danger" width="20%" @click="onMessage('error')">danger</m-button>
    </div>
  </div>
</template>

<script>
import { defineComponent,reactive,toRefs,getCurrentInstance } from 'vue'

export default defineComponent({
  props: {
    tabsIndex: {
      type: String,
			default: ''
    }
  },
  setup() {
    const instance = getCurrentInstance()
    const data = reactive({
      visible: false,
      inputValue0: '',
      inputValue1: '欢迎你的到来！',
      inputValue2: '',
      switchValue0: true,
      switchValue1: false,
      switchValue2: false,
      radioValue1: 0,
      radioValue2: 1,
      checkboxValue: false,
      checkboxValue0: true,
      checkboxValue1: false,
      checkboxValue2: false,
      checkboxValue3: true,
      checkboxValue4: false,
      checkboxValue5: false,
      model: {
        username: '',
        active: true
      }
    })
    // dialog 触发显示弹出框
    const onVisible = () => {
      data.visible = data.visible ? false : true
    }
    const onConfirm = () => {
      data.visible = false
      console.log('点击确认按钮')
    }
    // switch
    const onChange = () => {
      console.log('7')
    }
    // message
    const onMessage = (e) => {
      instance.proxy.$message({
        text: e,
          // timeout:'40000',
          // textColor:"#000",
          // bgColor:"red",
          // color: "red",
          // icon: 'm-icon-shanchu',
          type: e, // success info warn error custom
        })
    }

    return {
      ...toRefs(data),
      onVisible,
      onConfirm,
      onChange,
      onMessage
    }
  },
})
</script>

<style lang="scss">
  .demo {
    background: #fff;
    padding: 20px 10px;
    .m-button {
      margin-right: 10px !important;
    }
    .m-switch {
      margin-right: 10px !important;
    }
    .m-form {
      margin-right: 60px !important;
    }
  }
</style>
