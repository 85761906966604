export default [
  {
    code: 'guide',
    name: '表单',
    children: [
      {
        path: '/docs/input',
        name: 'input',
        meta: {
          type: 'component',
          title: 'Input 输入框',
        },
        component: () => import('../../docs/input.md'),
      }, {
        path: '/docs/form',
        name: 'form',
        meta: {
          type: 'component',
          title: 'Form 表单',
        },
        component: () => import('../../docs/form.md'),
      }, {
        path: '/docs/dialog',
        name: 'dialog',
        meta: {
          type: 'component',
          title: 'Dialog 面板',
        },
        component: () => import('../../docs/dialog.md'),
      }
    ],
  },
];
