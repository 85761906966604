export default [
  {
    code: 'guide',
    name: '指南',
    children: [
      {
        path: '/docs/install',
        name: 'install',
        meta: {
          type: 'component',
          title: '安装',
        },
        component: () => import('../../docs/install.md'),
      },
      {
        path: '/docs/quick_start',
        name: 'quick_start',
        meta: {
          type: 'component',
          title: '快速开始',
        },
        component: () => import('../../docs/quick-start.md'),
      },{
        path: '/docs/FAQ',
        name: 'FAQ',
        meta: {
          type: 'component',
          title: '更新日志',
        },
        component: () => import('../../../FAQ.md'),
      },{
        path: '/home',
        name: 'Home',
        component: () => import('../../../examples/layout/home.vue'),
        meta: {
          type: '',
          // title: '百洋易美'
        }
      }
    ],
  },
];
