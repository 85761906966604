<template>
  <div class="nav-slide-container">
    <div :default-active="$route.name" :width="300">
      <ul
        v-for="(group, groupIndex) in NavConfig"
        :key="groupIndex">
        <div class="name">{{group.name}}</div>
        <li
          class="nav-item__name"
          :class="item.name == tansName ? 'item_bg' : ''"
          v-for="(item, index) in group.children"
          :key="index"
          @click="onrouter(item)"
          >
          {{item.meta.title}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { NavConfig } from '../router/modules/index.js';
import { defineComponent,reactive,toRefs } from 'vue';
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'DemoHeader',
  setup(props,{emit}) {
    const router = useRouter()
    const data = reactive({
      NavConfig,
      tansName: ''
    })
    const onrouter = (item) => {
      data.tansName = item.name
      emit('itemss',item.name)
      // router.push({
      //   path: item.path
      // })
    }
    return {
      ...toRefs(data),
      onrouter
    }
  }
})
</script>

<style lang="scss" scoped>
.nav-slide-container {
  background: #fff;
  padding: 20px 0;
  .mku-menu {
    border-right: 1px solid transparent !important;
  }
  ul {
    padding: 4px 20px;
    color: #808695;
    .name {
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      padding: 10px 0 10px 0;
    }
    li {
      // text-indent: 1em;
      position: relative;
      width: 100%;
      padding: 10px 20px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      cursor: pointer;
      text-align: left;
      font-size: 14px;
      &:hover {
        color: #2d8cf0;
        background: #f0fafe;
        outline: none;
        -webkit-user-select: none;
      }
      &:active {
        color: #fff;
        background: rgb(80, 182, 250) !important;
      }
    }
    .item_bg {
      color: #2d8cf0;
      background: #f0fafe;
      color: #0e80eb;
      background: rgba(14,128,235,.1);
      border-left: 3px solid #0e80eb;
      padding-left: 31px;
      transform: all .2s ease;
    }
  }
}
</style>
